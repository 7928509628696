@keyframes fadeSlideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-slide-up {
  opacity: 0;
  display: inline-block;
  animation: fadeSlideUp 0.5s ease-out forwards;
}

@media (max-width: 640px) {
  h1 {
    font-size: 2.5rem; /* Smaller font for mobile */
  }

  p {
    font-size: 1rem;
  }

  nav {
    flex-direction: column;
    text-align: center;
  }

  button {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 4rem; /* Larger font for tablets */
  }

  p {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 5rem; /* Larger font for desktops */
  }

  p {
    font-size: 1.5rem;
  }
}
